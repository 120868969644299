import { ListItem, ExtendedListItem } from './recursive-list';
import { ListItemTypes } from './constants';

export const createListTree = (data: ListItem[]) => {
    const result: ExtendedListItem[] = [];
    const folders: {
        [K: string]: ExtendedListItem[];
    } = {};

    data.forEach((item) => {
        const itemCopy: ExtendedListItem = Object.assign({}, item);

        if (item.type === ListItemTypes.folder) {
            if (!folders[item.id]) {
                folders[item.id] = [];
            }
            itemCopy.items = folders[item.id];
        }

        if (!item.parentId) {
            result.push(itemCopy);
        } else {
            if (!folders[item.parentId]) {
                folders[item.parentId] = [];
            }
            folders[item.parentId].push(itemCopy);
        }
    });

    return result;
};
