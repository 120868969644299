import React, { useCallback } from 'react';
import { TextFieldProps, TextField } from '@material-ui/core';
import classnames from 'classnames';

import './styles.css';

export type InputProps = Omit<TextFieldProps, 'multiline' | 'variant' | 'onChange' | 'error'> & {
    error?: string | boolean;
    maxLength?: number;
    onChange?: (value: string) => void;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    className?: string;
    readonly?: boolean;
};

export const Input: React.FC<InputProps> = ({
    value,
    error,
    fullWidth = true,
    label,
    maxLength = 200,
    onChange,
    startIcon,
    className,
    endIcon,
    readonly = false,
    InputProps,
    inputProps,
    ...rest
}) => {
    const onChangeHandler = useCallback(
        (event) => {
            onChange?.(event.target.value);
        },
        [onChange]
    );

    return (
        <TextField
            className={classnames('uif-input', className)}
            fullWidth={fullWidth}
            label={label}
            value={value || ''}
            variant={(readonly ? 'standard' : 'outlined') as any} // https://github.com/mui-org/material-ui/issues/15697
            {...rest}
            error={!!error}
            helperText={typeof error === 'string' ? error : ''}
            onChange={onChangeHandler}
            inputProps={{
                maxLength: maxLength,
                ...inputProps,
            }}
            InputProps={{
                ref: InputProps?.ref,
                startAdornment: startIcon,
                endAdornment: endIcon || InputProps?.endAdornment,
                disableUnderline: readonly,
                readOnly: readonly,
            }}
        />
    );
};
