import React from 'react';
import { SortIcon } from './../../../icons/SortIcon';
import { SortWrapper } from './SortIcon.styled';

export const Sort = ({ sortAsc = false, selected = false }: { sortAsc?: boolean; selected?: boolean }) => {
    const [hover, setHover] = React.useState(false);

    return (
        <SortWrapper
            onMouseLeave={() => setHover(false)}
            onMouseOver={() => setHover(true)}
            hovered={hover}
            sortAsc={sortAsc}
            selected={selected}
        >
            <SortIcon />
        </SortWrapper>
    );
};
