import React from 'react';
import { SvgComponentType } from './index.types';

export const Square = ({ color = '#A2A3B7', ...other }: SvgComponentType) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5ZM7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7Z"
                fill={color}
            />
        </g>
    </svg>
);
