import React, { FC } from 'react';
import { CustomColumn } from '../../x-grid-table';
import { EditableItemsLayout } from './EditableItemsLayout';
import { ItemsLayout } from './ItemsLayout';
import { SettingMenuWrapper } from './SettingMenu.styled';

type SettingMenuProps = {
    editableItems: CustomColumn[];
    items: CustomColumn[];
    onCheckboxClick: (field: string) => void;
    onEditCustomColumn?: (col: CustomColumn) => void;
    onRemoveCustomColumn?: (col: CustomColumn) => void;
};
export const SettingMenu: FC<SettingMenuProps> = ({
    editableItems,
    items,
    onCheckboxClick,
    onEditCustomColumn,
    onRemoveCustomColumn,
}) => (
    <SettingMenuWrapper>
        <ItemsLayout items={items} onCheckboxClick={onCheckboxClick} />
        <EditableItemsLayout
            editableItems={editableItems}
            onCheckboxClick={onCheckboxClick}
            onEditCustomColumn={onEditCustomColumn}
            onRemoveCustomColumn={onRemoveCustomColumn}
        />
    </SettingMenuWrapper>
);
