import React, { useCallback } from 'react';
import classnames from 'classnames';
import {
    Accordion as MuiAccordion,
    AccordionProps as MuiAccordionProps,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './styles.css';

export type AccordionProps = {
    title: string;
    onChange?: (props: { expanded: boolean }) => void;
    className?: string;
    actions?: React.ReactNode;
    disabled?: MuiAccordionProps['disabled'];
    defaultExpanded?: MuiAccordionProps['defaultExpanded'];
    dataTestIdPrefix?: string;
};

export const Accordion: React.FC<AccordionProps> = ({
    title,
    children,
    onChange,
    className,
    actions,
    disabled,
    defaultExpanded,
    dataTestIdPrefix,
}) => {
    const handleChange = useCallback(
        (_, expanded: boolean) => {
            if (onChange) {
                onChange({ expanded });
            }
        },
        [onChange]
    );

    return (
        <MuiAccordion
            defaultExpanded={defaultExpanded}
            disabled={disabled}
            className={classnames('ui-accordion-root', className)}
            onChange={handleChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="summary"
                classes={{ content: 'summary-content' }}
                data-test-id={`${dataTestIdPrefix}AccordionHeadBtn`}
            >
                <div className="title">{title}</div>
                <div className="actions" onClick={(event) => event.stopPropagation()}>
                    {actions}
                </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: 'details' }}>{children}</AccordionDetails>
        </MuiAccordion>
    );
};
