import React from 'react';
import { CustomColumn } from '../../x-grid-table';
import { ItemsLayoutWrapper } from './ItemsLayout.styled';
import { HeaderText, ItemCell, ItemText } from './SettingMenu.styled';
import { Checkbox } from '../../../../components/Checkbox';

export const ItemsLayout = ({
    items,
    onCheckboxClick,
}: {
    items: CustomColumn[];
    onCheckboxClick: (field: string) => void;
}) => {
    if (!items.length) {
        return null;
    }
    return (
        <ItemsLayoutWrapper>
            <HeaderText>Колонки позиции</HeaderText>
            {items.map((i) => (
                <ItemCell key={`${i.field}-${i.headerName}`}>
                    <Checkbox
                        checked={!i.hide}
                        disabled={i?.required}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onCheckboxClick(i.field);
                        }}
                    />
                    <ItemText>{i.headerName}</ItemText>
                </ItemCell>
            ))}
        </ItemsLayoutWrapper>
    );
};
