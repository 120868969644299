import React from 'react';
import { SvgComponentType } from './index.types';

export const SortIcon = ({ color = '#A2A3B7', ...other }: SvgComponentType) => (
    <svg width="10" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path
            d="M4.99992 7.06V1C4.99992 0.447716 4.55221 0 3.99992 0C3.44764 0 2.99992 0.447715 2.99992 1V7.06L1.89496 6.27156C1.44499 5.95049 0.819565 6.05585 0.499057 6.50622C0.179225 6.95564 0.28338 7.57961 0.73224 7.90023L3.41992 9.82C3.58992 9.94 3.79992 10 3.99992 10C4.19992 10 4.40993 9.94 4.57993 9.81L7.26856 7.88955C7.71689 7.56931 7.82125 6.94651 7.50179 6.49762C7.18088 6.04668 6.55447 5.94267 6.10504 6.2657L4.99992 7.06Z"
            fill={color}
        />
    </svg>
);
