import styled from 'styled-components';

export const ItemsLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const IconContainer = styled.div`
    display: flex;
    gap: 0;
    margin: 0;
`;
