import React from 'react';
import { SvgComponentType } from './index.types';

export const Checked = ({ color = '#00003D', ...other }: SvgComponentType) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7ZM16.74 10.1727C17.1115 9.76402 17.0814 9.13157 16.6727 8.76007C16.264 8.38856 15.6316 8.41868 15.2601 8.82733L10.9044 13.6186L8.64021 11.7318C8.21593 11.3782 7.58537 11.4355 7.2318 11.8598C6.87824 12.2841 6.93556 12.9147 7.35984 13.2682L10.3598 15.7682C10.7713 16.1111 11.3797 16.0689 11.74 15.6727L16.74 10.1727Z"
            fill={color}
        />
    </svg>
);
