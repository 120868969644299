import React, { useState, useCallback } from 'react';
import classnames from 'classnames';

import { AppBar, AppBarProps, Tab, Tabs as MuiTabs } from '@material-ui/core';

import './styles.css';

type TabType = {
    label: string;
    id: number;
};

export type TabsProps = {
    tabs: TabType[];
    value?: number;
    position?: AppBarProps['position'];
    className?: string;
    onChange?: (tabIndex: number) => void;
    dataTestIdPrefix?: string;
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Tabs: React.FC<TabsProps> = ({
    tabs,
    value = 0,
    position = 'static',
    className,
    onChange,
    dataTestIdPrefix,
}) => {
    const [index, setIndex] = useState(value);
    const handleChangeTab = useCallback(
        (_, newTabIndex: number) => {
            if (onChange) {
                onChange(newTabIndex);
            }
            setIndex(newTabIndex);
        },
        [onChange]
    );

    return (
        <AppBar position={position} className={classnames('app-bar', className)}>
            <MuiTabs
                className="tabs"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                value={index}
                onChange={handleChangeTab}
                aria-label="view-tabs"
            >
                {tabs?.map(({ label, id }, idx) => (
                    <Tab
                        data-test-id={`${dataTestIdPrefix}_${idx}`}
                        key={id}
                        className="tab"
                        label={label}
                        {...a11yProps(id)}
                    />
                ))}
            </MuiTabs>
        </AppBar>
    );
};
