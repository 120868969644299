import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { Input } from '../input';

import './styles.css';

export type Option = {
    value: string;
    group?: string;
};

export type AutocompleteSearchProps = {
    className?: string;
    /**
     * Number of chars in search input that trigger autocomplete fetch
     * @default 3
     */
    startAutocompleteCharsNumber?: number;
    onSelect?: (value?: string | null) => void;
    getOptions?: (value: string) => Promise<Option[]>;
    dataTestId?: string;
};

export const AutocompleteSearch: React.FC<AutocompleteSearchProps> = ({
    className,
    startAutocompleteCharsNumber = 3,
    onSelect,
    getOptions,
    dataTestId,
}) => {
    const [options, setOptions] = useState<Option[]>([]);
    const [value, setValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const selectHandler = useCallback(
        (_, option: Option | string | null) => {
            if (onSelect) {
                onSelect(typeof option === 'string' ? option : option?.value);
            }
            setOptions([]);
        },
        [onSelect]
    );
    const fetch = useCallback(
        async (value: string) => {
            if (getOptions) {
                setOptions(await getOptions(value));
            }
        },
        [getOptions]
    );

    useEffect(() => {
        if (value && value.length >= startAutocompleteCharsNumber) {
            fetch(value);
        }
    }, [value]);

    return (
        <Autocomplete
            freeSolo={true}
            blurOnSelect={true}
            onChange={selectHandler}
            onInputChange={(_, value, reason) => {
                if (!value || reason === 'clear') {
                    setOptions([]);
                }
                setValue(value);
            }}
            getOptionLabel={(option: string | Option) => (typeof option === 'string' ? option : option?.value)}
            className={classNames('autocomplete-search', className)}
            inputValue={value}
            open={open}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            options={options}
            groupBy={(option) => option?.group || ''}
            renderInput={(params: any) => (
                <Input
                    {...params}
                    startIcon={<SearchIcon className="search-icon" />}
                    placeholder="Поиск"
                    data-test-id={`${dataTestId}`}
                />
            )}
        />
    );
};
