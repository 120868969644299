import React from 'react';
import { FillSquareMinus, Checked, Square } from '../../icons';

import { CheckboxContainer, HiddenCheckbox, CheckboxIconWrapper } from './Checkbox.styled';

export type CheckboxProps = {
    className?: string;
    checked: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    preventDefault?: boolean;
    onClick?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onClick'>;

export const Checkbox = React.memo(({ className, checked, indeterminate, onClick, ...props }: CheckboxProps) => {
    const getIcon = () => {
        if (indeterminate) {
            return <FillSquareMinus color={props.disabled ? 'rgba(0, 0, 61, 0.25)' : '#DFE0E5'} />;
        }
        if (checked) {
            return <Checked color={props.disabled ? 'rgba(76, 94, 207, 0.25)' : '#4C5ECF'} />;
        }
        return <Square color={props.disabled ? 'rgba(53, 59, 96, 0.10)' : 'rgba(53, 59, 96, 0.35)'} />;
    };
    return (
        <CheckboxContainer data-test-id="checkbox" onClick={onClick} className={className} disabled={props.disabled}>
            <HiddenCheckbox checked={checked} {...(props as any)} />

            <CheckboxIconWrapper>{getIcon()}</CheckboxIconWrapper>
        </CheckboxContainer>
    );
});

Checkbox.displayName = 'Checkbox';
