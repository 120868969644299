import React, { Key, useCallback } from 'react';
import classnames from 'classnames';
import { List as MuiList, ListItem as MuiListItem, ListItemText, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { SubHeader } from './subheader';
import { ListItemTypes } from './constants';

export type ListItem = {
    parentId: Key | null;
    id: Key;
    name: string;
    type: keyof typeof ListItemTypes;
    count?: number;
};

export type ExtendedListItem = ListItem & {
    items?: ExtendedListItem[];
};

type Opened = {
    [K: string]: boolean;
};

export type RecursiveListProps = {
    className?: string;
    title?: string;
    items?: ExtendedListItem[];
    opened: Opened;
    actions?: React.ReactChild;
    selected?: Key | null;
    setOpened: (newOpened: Opened) => void;
    onItemClick?: (id: Key) => void;
    onParentItemClick?: (id: Key) => void;
    dataTestIdPrefix?: string;
};

const getItemName = (name: string, count?: number) => `${name}${count !== undefined ? ` (${count})` : ''}`;

export const RecursiveList: React.FC<RecursiveListProps> = ({
    className,
    title,
    opened,
    items,
    actions,
    selected,
    setOpened,
    onParentItemClick,
    onItemClick,
    dataTestIdPrefix,
}) => {
    const onListItemClickHandler = useCallback(
        (type: ListItem['type'], id: Key) => {
            if (type === ListItemTypes.folder) {
                setOpened({
                    ...opened,
                    [id]: !opened[id],
                });
                if (onParentItemClick) {
                    onParentItemClick(id);
                }
            }
            if (type === ListItemTypes.list) {
                if (onItemClick) {
                    onItemClick(id);
                }
            }
        },
        [setOpened, opened, onParentItemClick, onItemClick]
    );

    return (
        <MuiList
            component="div"
            className={classnames('recursive-list', className)}
            subheader={<SubHeader title={title} actions={actions} />}
        >
            {!!items?.length &&
                items.map(({ id, name, items, type, count }, idx) => (
                    <React.Fragment key={id}>
                        <MuiListItem
                            data-test-id={`${dataTestIdPrefix}_${idx}`}
                            className={classnames({
                                selected: id === selected,
                            })}
                            button
                            onClick={() => onListItemClickHandler(type, id)}
                        >
                            {type === ListItemTypes.folder &&
                                (opened[id] ? (
                                    <ExpandLess className="expand-icon" />
                                ) : (
                                    <ExpandMore className="expand-icon" />
                                ))}
                            <ListItemText primary={getItemName(name, count)} />
                        </MuiListItem>
                        {type === ListItemTypes.folder && (
                            <Collapse in={opened[id]} timeout="auto" unmountOnExit>
                                <RecursiveList
                                    items={items}
                                    opened={opened}
                                    selected={selected}
                                    setOpened={setOpened}
                                    onItemClick={onItemClick}
                                    onParentItemClick={onParentItemClick}
                                    dataTestIdPrefix={`${dataTestIdPrefix}_${idx}`}
                                />
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
        </MuiList>
    );
};
