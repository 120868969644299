import styled from 'styled-components';

type CheckboxContainerProps = {
    disabled?: boolean;
};

export const CheckboxContainer = styled.label`
    display: flex;
    cursor: ${(props: CheckboxContainerProps) => (props.disabled ? 'default' : 'pointer')};

    & path {
        transition: all ease-out 0.1s;
    }

    &:hover {
        & path {
            ${(props: CheckboxContainerProps) => !props.disabled && 'fill: #2235AA;'};
        }
    }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const CheckboxIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;
