import styled from 'styled-components';

type SortWrapperProps = {
    sortAsc?: boolean;
    hovered?: boolean;
    selected?: boolean;
};

export const SortWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: ${(props: SortWrapperProps) => (props.sortAsc ? 'rotate(180deg)' : 'initial')};
    & path {
        ${({ selected, hovered }: SortWrapperProps) => (hovered || selected) && 'fill: #353B60;'};
    }
`;
