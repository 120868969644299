import React from 'react';
import classnames from 'classnames';
import { ButtonGroup } from '@material-ui/core';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import { CatalogProps } from '.';
import { Button } from '../button';
import { ButtonWithMenu } from '../button-with-menu';
import { List } from '../list';

import './styles.css';

export const CollapsedCatalog: React.FC<CatalogProps> = ({
    className,
    items,
    title,
    highlightSelected,
    onItemClick,
    onParentItemClick,
    needSwitchBtn,
    buttonType = 'primary',
    onSwitch,
    dataTestIdPrefix,
}) => (
    <ButtonGroup>
        <ButtonWithMenu
            data-test-id={`${dataTestIdPrefix}collapsedCatalogOpenBtn`}
            buttonTitle={title}
            needStartIcon
            buttonType={buttonType}
        >
            <List
                className={classnames('collapsed', className)}
                highlightSelected={highlightSelected}
                items={items}
                title={title}
                onItemClick={onItemClick}
                onParentItemClick={onParentItemClick}
                dataTestIdPrefix={`${dataTestIdPrefix}collapsedCatalogListItem`}
            />
        </ButtonWithMenu>
        {needSwitchBtn && (
            <Button
                data-test-id={`${dataTestIdPrefix}collapsedCatalogExpandBtn`}
                type="secondary"
                title={<FormatIndentIncreaseIcon />}
                onClick={onSwitch}
                className="action-button"
            />
        )}
    </ButtonGroup>
);
