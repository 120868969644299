import React, { Key, useMemo, useState } from 'react';
import { RecursiveList, ListItem, RecursiveListProps } from './recursive-list';
import { createListTree } from './utils';

import './styles.css';
import { useCallback } from 'react';
import { useEffect } from 'react';

export type ListProps = {
    className?: string;
    title?: string;
    items: ListItem[];
    actions?: RecursiveListProps['actions'];
    /**
     * Определяет выделение выбранного пункта списка,
     * можно задать выбранный пункт меню указав
     * в качестве значения пропса id пункта списка
     * @default false
     */
    highlightSelected?: boolean | Key | null;
    onItemClick: RecursiveListProps['onItemClick'];
    onParentItemClick: RecursiveListProps['onParentItemClick'];
    dataTestIdPrefix?: string;
};

export const List: React.FC<ListProps> = ({
    className,
    title,
    items,
    actions,
    highlightSelected = false,
    onItemClick,
    onParentItemClick,
    dataTestIdPrefix,
}) => {
    const [opened, setOpened] = useState({});
    const [selected, setSelected] = useState<Key | null>(
        typeof highlightSelected !== 'boolean' && !!highlightSelected ? highlightSelected : ''
    );
    const itemsTree = useMemo(() => createListTree(items), [items]);
    const onItemClickHandler = useCallback(
        (id: Key) => {
            if (onItemClick) {
                onItemClick(id);
            }
            if (highlightSelected !== false) {
                setSelected(id);
            }
        },
        [onItemClick, highlightSelected]
    );

    const onParentClickHandler = useCallback(
        (id: Key) => {
            if (onParentItemClick) {
                onParentItemClick(id);
            }
            if (onParentItemClick && highlightSelected !== false) {
                setSelected(id);
            }
        },
        [onParentItemClick, highlightSelected]
    );

    useEffect(() => {
        if (typeof highlightSelected !== 'boolean') {
            setSelected(highlightSelected);
        }
    }, [highlightSelected]);

    return (
        <RecursiveList
            className={className}
            title={title}
            items={itemsTree}
            opened={opened}
            actions={actions}
            setOpened={setOpened}
            onItemClick={onItemClickHandler}
            onParentItemClick={onParentClickHandler}
            selected={selected}
            dataTestIdPrefix={dataTestIdPrefix}
        />
    );
};
