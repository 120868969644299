import { IconButton } from '@gmini/ui-kit';
import { Edit, HighlightOff } from '@material-ui/icons';
import React from 'react';
import { Checkbox } from '../../../../components/Checkbox';
import { CustomColumn } from '../../x-grid-table';
import { IconContainer, ItemsLayoutWrapper } from './ItemsLayout.styled';
import { HeaderText, ItemCell, ItemText } from './SettingMenu.styled';

export const EditableItemsLayout = ({
    editableItems,
    onCheckboxClick,
    onEditCustomColumn,
    onRemoveCustomColumn,
}: {
    editableItems: CustomColumn[];
    onCheckboxClick: (field: string) => void;
    onEditCustomColumn?: (col: CustomColumn) => void;
    onRemoveCustomColumn?: (col: CustomColumn) => void;
}) => {
    if (!editableItems.length) {
        return null;
    }
    return (
        <ItemsLayoutWrapper>
            <HeaderText>Колонки поставщика</HeaderText>
            {editableItems.map((item) => (
                <ItemCell key={`${item.field}-${item.headerName}`}>
                    <Checkbox
                        checked={!item.hide}
                        disabled={item?.required}
                        onClick={(e) => {
                            e.preventDefault();
                            onCheckboxClick(item.field);
                        }}
                    />
                    <ItemText>{item.headerName}</ItemText>
                    <IconContainer>
                        {onEditCustomColumn && (
                            <IconButton size="small" onClick={() => onEditCustomColumn(item)}>
                                <Edit />
                            </IconButton>
                        )}
                        {!item.required && onRemoveCustomColumn && (
                            <IconButton size="small" onClick={() => onRemoveCustomColumn(item)}>
                                <HighlightOff />
                            </IconButton>
                        )}
                    </IconContainer>
                </ItemCell>
            ))}
        </ItemsLayoutWrapper>
    );
};
