import styled from 'styled-components';

export const SettingMenuWrapper = styled.div`
    width: 100%;
    padding: 0 12px 0 7px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
`;

export const HeaderText = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #a2a3b7;
    cursor: default;
`;

export const ItemText = styled.div`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #000335;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
`;

export const ItemCell = styled.div`
    display: flex;
    gap: 5px;
    padding: 7px 0px;
    align-items: center;
`;
