import React from 'react';
import { SvgComponentType } from './index.types';

export const FillSquareMinus = ({ color = '#DFE0E5', ...other }: SvgComponentType) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
        <rect x="4" y="4" width="16" height="16" rx="3" fill={color} stroke={color} strokeWidth="2" />
        <path
            d="M15 13H9C8.45 13 8 12.55 8 12C8 11.45 8.45 11 9 11H15C15.55 11 16 11.45 16 12C16 12.55 15.55 13 15 13Z"
            fill="#353B60"
        />
    </svg>
);
