import { ListSubheader } from '@material-ui/core';
import React from 'react';

type Props = {
    title?: string;
    actions?: React.ReactChild;
};

export const SubHeader: React.FC<Props> = ({ title, actions }) =>
    !!title || !!actions ? (
        <ListSubheader className="subheader">
            <div className="title">{title}</div>
            <div className="actions">{actions}</div>
        </ListSubheader>
    ) : null;
